// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../../../../../css-loader/dist/cjs.js??clonedRuleSet-2.use[1]!../../../../../../../vue-loader/dist/stylePostLoader.js!../../../../../../../postcss-loader/dist/cjs.js??clonedRuleSet-2.use[2]!../../../../../../../sass-loader/dist/cjs.js??clonedRuleSet-2.use[3]!../../../../../../../vue-loader/dist/index.js??ruleSet[0]!./AposTiptapDivider.vue?vue&type=style&index=0&id=23f0af64&lang=scss&scoped=true");
if(content.__esModule) content = content.default;
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add the styles to the DOM
var add = require("!../../../../../../../vue-style-loader/lib/addStylesClient.js").default
var update = add("4e0c82ed", content, false, {});
// Hot Module Replacement
if(module.hot) {
 // When the styles change, update the <style> tags
 if(!content.locals) {
   module.hot.accept("!!../../../../../../../css-loader/dist/cjs.js??clonedRuleSet-2.use[1]!../../../../../../../vue-loader/dist/stylePostLoader.js!../../../../../../../postcss-loader/dist/cjs.js??clonedRuleSet-2.use[2]!../../../../../../../sass-loader/dist/cjs.js??clonedRuleSet-2.use[3]!../../../../../../../vue-loader/dist/index.js??ruleSet[0]!./AposTiptapDivider.vue?vue&type=style&index=0&id=23f0af64&lang=scss&scoped=true", function() {
     var newContent = require("!!../../../../../../../css-loader/dist/cjs.js??clonedRuleSet-2.use[1]!../../../../../../../vue-loader/dist/stylePostLoader.js!../../../../../../../postcss-loader/dist/cjs.js??clonedRuleSet-2.use[2]!../../../../../../../sass-loader/dist/cjs.js??clonedRuleSet-2.use[3]!../../../../../../../vue-loader/dist/index.js??ruleSet[0]!./AposTiptapDivider.vue?vue&type=style&index=0&id=23f0af64&lang=scss&scoped=true");
     if(newContent.__esModule) newContent = newContent.default;
     if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
     update(newContent);
   });
 }
 // When the module is disposed, remove the <style> tags
 module.hot.dispose(function() { update(); });
}