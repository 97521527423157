<template>
  <svg viewBox="0 0 230.39 230.39">
    <defs>
      <linearGradient
        id="circle"
        x1="173.17"
        y1="86.95"
        x2="141.86"
        y2="141.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#cc9300" />
        <stop offset="0.47" stop-color="#ea433a" />
        <stop offset="1" stop-color="#b327bf" />
      </linearGradient>
      <linearGradient
        id="square"
        x1="92.49"
        y1="41"
        x2="67.07"
        y2="110.85"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#cc9300" />
        <stop offset="0.26" stop-color="#ea433a" />
        <stop offset="0.47" stop-color="#b327bf" />
        <stop offset="0.76" stop-color="#66f" />
        <stop offset="1" stop-color="#00bf9a" />
      </linearGradient>
      <linearGradient
        id="triangle"
        x1="75.13"
        y1="190.31"
        x2="120.2"
        y2="143.64"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#b327bf" />
        <stop offset="0.47" stop-color="#66f" />
        <stop offset="1" stop-color="#00c09a" />
      </linearGradient>
    </defs>
    <path
      d="M154.49,220.39l-44.16,7c-30.12,4.77-41.53,3.36-53.48-.78a62.25,62.25,0,0,1-29.59-21.5C19.63,195,14.77,184.61,10,154.49L3,110.33C-1.77,80.21-.36,68.8,3.78,56.85a62.25,62.25,0,0,1,21.5-29.59C35.36,19.63,45.78,14.77,75.9,10l44.16-7c30.12-4.77,41.53-3.36,53.48.78a62.18,62.18,0,0,1,29.58,21.5c7.64,10.08,12.5,20.5,17.27,50.62l7,44.16c4.77,30.12,3.36,41.53-.78,53.48a62.18,62.18,0,0,1-21.5,29.58C195,210.76,184.61,215.62,154.49,220.39Z"
      fill="#000000"
    />
    <path
      d="M162.43,145.1a31.43,31.43,0,1,0-35.65-26.17A31.28,31.28,0,0,0,162.43,145.1Zm-1.57-9.94a21.37,21.37,0,1,1,17.45-24.39A21.2,21.2,0,0,1,160.86,135.16Z"
      fill="url(#circle)"
    />
    <path
      d="M61,107.94l46.64-7.38a5,5,0,0,0,4.18-5.76l-7.4-46.71a5,5,0,0,0-5.76-4.19L52,51.29a5,5,0,0,0-4.18,5.76l7.4,46.71A5,5,0,0,0,61,107.94Zm3.39-10.72L58.52,60.45l36.7-5.81L101,91.41Z"
      fill="url(#square)"
    />
    <path
      d="M90.56,124.91,70.33,181.56a5,5,0,0,0,5.53,6.67l56.94-9a5,5,0,0,0,3.2-8.05L99.29,123.53A5,5,0,0,0,90.56,124.91ZM96.94,137l25.91,33.62L82.66,177Z"
      fill="url(#triangle)"
    />
  </svg>
</template>
