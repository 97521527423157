import { defineComponent, useAttrs, ref, computed, watch, onUnmounted, openBlock, createBlock, resolveDynamicComponent, normalizeClass, withCtx, renderSlot, createElementBlock, Fragment, renderList } from 'vue';
import Sortable from 'sortablejs';

const _sfc_main = /* @__PURE__ */ defineComponent({
  __name: "Sortable",
  props: {
    /** All SortableJS options are supported; events are handled by the `defineEmits` below and should be used with v-on */
    options: {
      type: Object,
      default: null,
      required: false
    },
    /** Your list of items **/
    list: {
      type: [Array, Object],
      default: [],
      required: true
    },
    /** The name of the key present in each item in the list that corresponds to a unique value. */
    itemKey: {
      type: [String, Function],
      default: "",
      required: true
    },
    /** The element type to render as. */
    tag: {
      type: String,
      default: "div",
      required: false
    }
  },
  emits: ["choose", "unchoose", "start", "end", "add", "update", "sort", "remove", "filter", "move", "clone", "change"],
  setup(__props, { expose: __expose, emit: __emit }) {
    const props = __props;
    const emit = __emit;
    const attrs = useAttrs();
    const isDragging = ref(false);
    const containerRef = ref(null);
    const sortable = ref(null);
    const getKey = computed(() => {
      if (typeof props.itemKey === "string")
        return (item) => item[props.itemKey];
      return props.itemKey;
    });
    __expose({
      containerRef,
      sortable,
      isDragging
    });
    watch(containerRef, (newDraggable) => {
      if (newDraggable) {
        sortable.value = new Sortable(newDraggable, {
          ...props.options,
          onChoose: (event) => emit("choose", event),
          onUnchoose: (event) => emit("unchoose", event),
          onStart: (event) => {
            isDragging.value = true;
            emit("start", event);
          },
          onEnd: (event) => {
            setTimeout(() => {
              isDragging.value = false;
              emit("end", event);
            });
          },
          onAdd: (event) => emit("add", event),
          onUpdate: (event) => emit("update", event),
          onSort: (event) => emit("sort", event),
          onRemove: (event) => emit("remove", event),
          onFilter: (event) => emit("filter", event),
          // See https://github.com/MaxLeiter/sortablejs-vue3/pull/56 for context on `attrs`.
          onMove: (event, originalEvent) => "onMoveCapture" in attrs ? (
            /**  eslint-disable-next-line */
            attrs.onMoveCapture(event, originalEvent)
          ) : emit("move", event, originalEvent),
          onClone: (event) => emit("clone", event),
          onChange: (event) => emit("change", event)
        });
      }
    });
    watch(
      () => props.options,
      (options) => {
        if (options && sortable?.value) {
          for (const property in options) {
            sortable.value.option(
              property,
              options[property]
            );
          }
        }
      }
    );
    onUnmounted(() => {
      if (sortable.value) {
        sortable.value.destroy();
        containerRef.value = null;
        sortable.value = null;
      }
    });
    return (_ctx, _cache) => {
      return openBlock(), createBlock(resolveDynamicComponent(_ctx.$props.tag), {
        ref_key: "containerRef",
        ref: containerRef,
        class: normalizeClass(_ctx.$props.class)
      }, {
        default: withCtx(() => [
          renderSlot(_ctx.$slots, "header"),
          (openBlock(true), createElementBlock(Fragment, null, renderList(__props.list, (item, index) => {
            return renderSlot(_ctx.$slots, "item", {
              key: getKey.value(item),
              element: item,
              index
            });
          }), 128)),
          renderSlot(_ctx.$slots, "footer")
        ]),
        _: 3
      }, 8, ["class"]);
    };
  }
});

export { _sfc_main as Sortable };
